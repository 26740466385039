import React from 'react';
import Layout from '../components/Layout';
import Banner from '../components/Banner';

const NotFoundPage = () => (
  <Layout>
    <main>
      <Banner heading="404, Page Not Found" />
    </main>
  </Layout>
);

export default NotFoundPage;
